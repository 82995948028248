/**
 * WX Design System: https://www.figma.com/file/g62poZvNdti6F6wxMTFi93/Fountain-WXP-New-Design-System
 */

// Tailwind Slate
const slate = {
  50: '#f8fafc',
  100: '#f1f5f9',
  200: '#e2e8f0',
  300: '#cbd5e1',
  400: '#94a3b8',
  500: '#64748b',
  600: '#475569',
  700: '#334155',
  800: '#1e293b',
  900: '#0f172a',
  950: '#020617',
};

// Tailwind Indigo
const indigo = {
  50: '#eef2ff',
  100: '#e0e7ff',
  200: '#c7d2fe',
  300: '#a5b4fc',
  400: '#818cf8',
  500: '#6366f1',
  600: '#4f46e5',
  700: '#4338ca',
  800: '#3730a3',
  900: '#312e81',
  950: '#1e1b4b',
};

// Tailwind Rose / WX Design System Rose
const rose = {
  50: '#fff1f2',
  600: '#e11d48',
};

// WX Design System Amber
const amber = {
  50: '#fff8e1',
  100: '#ffe6cd',
  600: '#f68006',
};

// WX Design System Green
const green = {
  50: '#d8f9d3',
  600: '#4ea441',
};

// WX Design System Yellow
const yellow = {
  50: '#fffee1',
  400: '#ffcf23',
};

// WX Design System Teal
const teal = {
  50: '#e3fafa',
  400: '#39d4d6',
};

// WX Design System Blue
const blue = {
  50: '#e7f3ff',
  100: '#edf6ff',
  200: '#ddf3ff',
  400: '#1864fd',
  500: '#357fef',
};

// WX Design System Purple
const purple = {
  50: '#f4e6ff',
  400: '#a738ff',
};

export default {
  white: '#fff',
  black: '#000',

  // Colors in the `wx` workspace are the only ones we should use and have in the codebase (apart from black and white)
  wx: {
    primary: indigo[600],
    secondary: slate[600],

    neutral: {
      50: slate[50],
      100: slate[100],
      200: slate[200],
      300: slate[300],
      400: slate[400],
      500: slate[500],
      600: slate[600],
      700: slate[700],
      800: slate[800],
      900: slate[900],
    },

    brand: {
      50: indigo[50],
      100: indigo[100],
      200: indigo[200],
      300: indigo[300],
      400: indigo[400],
      500: indigo[500],
      600: indigo[600],
    },

    danger: {
      light: rose[50],
      dark: rose[600],
    },

    warning: {
      light: amber[50],
      dark: amber[600],
    },

    success: {
      light: green[50],
      dark: green[600],
    },

    rose,
    amber,
    green,

    yellow,
    teal,
    blue,
    purple,
  },
  tw: {
    green: {
      400: '#4ade80', // Do not expand these or use this! Everything should come from our design system colors above. We're trying to get this color removed, not expanded
    },
  },
};
