import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';

/**
 * Sets CSS :root variables for a given object.
 * Supports handling any level of nested properties.
 *
 * @param {string} type - The type of properties. It will be used as the prefix, for example if type = 'color', the generated variable will be under the form `--color-*`.
 * @param {Object} properties - The object containing values.
 * @param {string} [prefix] - The optional prefix for nested properties.
 */
export function setCssRootVariables(
  type: string,
  properties: Record<string, unknown>,
  prefix?: string,
) {
  if (typeof window !== 'undefined') {
    const root = document.documentElement;

    // Iterate over the keys of the colors object
    Object.keys(properties).forEach(key => {
      const prefixedKey = `${prefix || ''}${key}`;
      const variableName = `--${type}-${prefixedKey}`;
      const value = properties[key];

      // If the value is an object, recursively call the function
      if (typeof value === 'object' && value !== null) {
        setCssRootVariables(
          type,
          value as Record<string, unknown>,
          `${prefixedKey}-`,
        );
      } else {
        // Set the CSS :root variable for non-nested properties
        root.style.setProperty(variableName, value as string);
      }
    });
  }
}

/**
 * Get the value for a given CSS variable.
 * @param {string} variableName - The CSS variable's name, for example `--color-wx-primary`
 */
export function getCssVariable(variableName: string) {
  if (typeof window !== 'undefined') {
    return getComputedStyle(document.documentElement).getPropertyValue(
      variableName,
    );
  }

  return '';
}

/**
 * Set the value for a given CSS variable.
 * @param {string} variableName - The CSS variable's name, for example `--color-wx-primary`
 * @param {string} value - The CSS variable's value, for example `#ff0000`
 */
export function setCssVariable(variableName: string, value: string) {
  if (typeof window !== 'undefined') {
    document.documentElement.style.setProperty(variableName, value);
  }
}

/**
 * Generates a configuration object with CSS variables based on the provided type (color, spacing, etc.) and properties. It recursively processes nested objects and replaces values with corresponding CSS variables.
 *
 * @param {string} type - The type of properties. It will be used as the prefix, for example if type = 'color', the generated variables will be under the form `--color-*`.
 * @param {Object} properties - The object containing values.
 * @param {string} prefix - The optional prefix for nested properties.
 */
export function generateConfigWithCssVariables(
  type: string,
  properties: Record<string, unknown>,
  prefix?: string,
) {
  const result: Record<string, unknown> = {};

  Object.keys(properties).forEach(key => {
    const value = properties[key];
    const prefixedKey = `${prefix || ''}${key}`;

    if (typeof value === 'string') {
      // Replace color code with CSS variable
      const variableName = `--${type}-${prefixedKey}`;
      result[key] = `var(${variableName})`;
    } else if (typeof value === 'object') {
      // Recursively process nested objects
      const nestedPrefix = `${prefixedKey}-`;
      result[key] = generateConfigWithCssVariables(
        type,
        value as Record<string, unknown>,
        nestedPrefix,
      );
    }
  });

  return result;
}

/**
 * Helper function to make classnames plays well with Tailwind and avoid classes conflitcs when using Tailwind to build generic components.
 *
 * @param args classnames' arguments
 * @returns a string containing classnames correctly merged
 */
export function cx(...args: classnames.ArgumentArray) {
  return twMerge(classnames(args));
}
