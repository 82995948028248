import { Navigate, useSearchParams } from 'react-router-dom';
import { useWorkerPath } from '@/hooks/worker/useWorkerPath';

// **** Deprecated Page component ****
// The path /pulse-check/[surveyUuid]?surveyResponseUud=[surveyResponseUuid] will be deprecated
// in favor of a more simple path /pulse-checks/[surveyResponseUuid]
//
// The only purpose of this page component is to re-route workers to the new path until we're 100% sure
// there are no more workers that contain links to the deprecated path
export default function Page() {
  const { workerHome } = useWorkerPath();
  const [searchParams] = useSearchParams();
  const surveyResponseUuid = searchParams.get('surveyResponseUuid');

  if (!surveyResponseUuid) {
    return <Navigate to={`${workerHome}/pulse-check`} />;
  }

  return <Navigate to={`${workerHome}/pulse-checks/${surveyResponseUuid}`} />;
}
