/* eslint-disable @typescript-eslint/no-explicit-any */

import ErrorPage from '@/components/pages/_shared/ErrorPage';
import UiLoaderFullPage from '@/components/ui/LoaderFullPage';
import { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { join } from 'path';

const maildevWebHost: string = import.meta.env.VITE_MAILDEV_WEB_HOST || '';
const maildevWebPort: string = import.meta.env.VITE_MAILDEV_WEB_PORT
  ? `:${Number(import.meta.env.VITE_MAILDEV_WEB_PORT)}`
  : '';

export interface Route {
  path?: string;
  children?: Route[];
  level?: number;
  fullPath?: string;
  element?: any;
}

/**
 * Configures a route by updating its properties and adding fallback and error elements.
 * @param route - The route to configure.
 * @param level - The level of the route in the route hierarchy.
 * @param path - The path of the current route.
 * @returns The configured route.
 */
export function configureRoute(route: Route, level = 0, _path = ''): any {
  if (level === 0) {
    if (route.path === 'layout' && route.element && route.children) {
      return {
        ...route,
        element: (
          // This is the upmost Suspense of the application
          // If we don't use a full page loader here then we'll have a plain blank screen most of the time, which isn't great
          <Suspense fallback={<UiLoaderFullPage />}>
            <route.element>
              <Outlet />
            </route.element>
          </Suspense>
        ),
        path: '/',
        errorElement: <ErrorPage />,
        children: [
          ...route.children,
          {
            index: true,
            element: (
              <ul className="px-6 py-4">
                Go to:
                <li>
                  👉 <Link to="/employer">Employer dashboard</Link>
                </li>
                <li>
                  👉 <Link to="/worker">Worker portal</Link>
                </li>
                <li>
                  👉{' '}
                  <Link
                    to={`http://${maildevWebHost + maildevWebPort}`}
                    target="_blank"
                  >
                    MailDev mail interceptor
                  </Link>
                </li>
              </ul>
            ),
          },
        ],
      };
    }
  } else {
    if (route.path === 'layout') {
      delete route.path;

      return {
        ...route,
        element: <route.element />,
        errorElement: <ErrorPage />,
      };
    }
  }

  if (route.path === 'page') {
    delete route.path;

    return {
      ...route,
      index: true,
      element: <route.element />,
      errorElement: <ErrorPage />,
    };
  }

  // Handles route segments with parenthesis (optional routes)
  if (route.path && /^\(.*\)$/.test(route.path)) {
    delete route.path;

    return {
      ...route,
    };
  }

  return route;
}

/**
 * Generates the routes by recursively configuring each route and constructing the route hierarchy.
 * @param routes - The array of routes to generate.
 * @param level - The level of the current route in the route hierarchy.
 * @param path - The path of the current route.
 * @returns The generated routes.
 */
export function makeRoutes(
  routes: Route[],
  level = 0,
  path = '',
  configureRouteFn = configureRoute,
): Route[] {
  return routes.map(route => {
    route = configureRouteFn(route, level, path);

    const fullPath = path ? join(path, route.path || '') : route.path;
    const children = route.children
      ? makeRoutes(route.children, level + 1, fullPath, configureRouteFn)
      : undefined;

    return {
      ...route,
      level,
      fullPath,
      children,
    };
  });
}
