import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './AppWorker.tsx';
import { initDatadogRum, initDatadogLogs } from './plugins/datadog.ts';
import '@/styles/globals.css';
import '@/styles/tailwind.css';

import { setBaseUrl } from '@/utils/_shared/apiUtils';

setBaseUrl();
initDatadogRum();
initDatadogLogs();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
