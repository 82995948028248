/* eslint-disable @typescript-eslint/no-explicit-any */

import { lazy } from 'react';

interface Route {
  path: string;
  children?: Route[];
  element?: any;
}

/**
 * Processes the given path by removing '/src/app' and '.tsx' occurrences, and replacing dynamic segments.
 *
 * @param {string} path - The path to process.
 * @returns {string} - The processed path.
 */
export function processPath(path: string) {
  return path.replace(/\/src\/app|\.tsx$/g, '').replace(/\[([^/]+)\]/g, ':$1');
}

/**
 * Constructs a tree structure from the imported routes.
 * Inspired by https://omarelhawary.me/blog/file-based-routing-with-react-location-nested-layouts/.
 *
 * @param {Record<string, any>} importedRoutes - The imported routes object.
 * @returns {Route[]} - The constructed tree structure.
 */
export function makeTree(
  importedRoutes: Record<string, any>,
  processPathFn = processPath,
) {
  return Object.keys(importedRoutes).reduce((routes: Route[], key) => {
    const module = importedRoutes[key];
    const route = {
      element: lazy(module),
    };

    const segments = processPathFn(key).split('/').filter(Boolean);

    segments.reduce(
      (parent, segment, index) => {
        const path = segment.replace(/index|\./g, '/');
        const root = index === 0;
        const leaf = index === segments.length - 1 && segments.length > 1;
        const node = !root && !leaf;
        // We define an insert key so we can choose to push at the beginning or the end of the parent array, to define the regular segments before dynamic ones for routes ranking.
        const insert = /^\w|\//.test(path) ? 'unshift' : 'push';

        if (root) {
          const last = segments.length === 1;
          if (last) {
            routes.push({ path, ...route });
            return parent;
          }
        }

        if (root || node) {
          const current = root ? routes : parent?.children;
          const found = current?.find(
            (route: { path: string }) => route.path === path,
          );
          if (found) found.children ??= [];
          else current?.[insert]({ path, children: [] });
          return (
            found || current?.[insert === 'unshift' ? 0 : current.length - 1]
          );
        }

        if (leaf) {
          parent?.children?.[insert]({ path, ...route });
        }

        return parent;
      },
      {} as Route | undefined,
    );

    return routes;
  }, []);
}

/**
 * Orders the routes tree by making the 'layout' element the parent of its siblings.
 *
 * @param {Route[]} routes - The routes tree to order.
 * @returns {Route[]} - The ordered routes tree.
 */
export function orderTree(routes: Route[]): Route[] {
  const newRoutes: any[] = [];

  const layoutElement = routes.find(element => element.path === 'layout');
  const remainingElements = routes.filter(element => element.path !== 'layout');

  if (layoutElement) {
    // Make the layout.tsx element the parent of its siblings
    newRoutes.push({
      ...layoutElement,
      children: orderTree(remainingElements),
    });
    return newRoutes;
  } else {
    for (const remainingElement of remainingElements) {
      if (remainingElement.children) {
        remainingElement.children = orderTree(remainingElement.children);
      }
    }

    return remainingElements;
  }
}
