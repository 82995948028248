import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './router/worker';
import { WORKER_BASE_PATH } from './utils/worker/constants';

const router = createBrowserRouter(routes, {
  basename: WORKER_BASE_PATH,
});

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
