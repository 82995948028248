import FountainLogo from '@/assets/svg/fountain-logo.svg';

export default function FallbackPage({
  empty = true,
  redirect = false,
}: {
  empty?: boolean;
  redirect?: boolean;
}) {
  if (window && redirect) {
    setTimeout(() => {
      window.location.href = 'https://www.fountain.com';
    }, 2000);
  }

  if (empty) return null;

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-6">
      <a href="https://www.fountain.com">
        <img src={FountainLogo} alt="Fountain Logo" />
      </a>
      <p>
        <a href="https://www.fountain.com">www.fountain.com</a>
      </p>
    </div>
  );
}
