export function useLogger() {
  function logError(message?: unknown, ...optionalParams: unknown[]) {
    console.error(`[WX Error]: ${message}`, ...optionalParams);
  }

  function logWarn(message?: unknown, ...optionalParams: unknown[]) {
    console.warn(`[WX Warning]: ${message}`, ...optionalParams);
  }

  function logInfo(message?: unknown, ...optionalParams: unknown[]) {
    console.info(`[WX Info]: ${message}`, ...optionalParams);
  }

  return {
    logError,
    logWarn,
    logInfo,
  };
}
