import { tv } from 'tailwind-variants';

export const button = tv({
  base: `inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg font-medium transition duration-200 focus:shadow-focus active:opacity-80`,
  slots: {
    startIcon: 'flex h-5 w-5 shrink-0 items-center justify-center',
    endIcon: 'flex h-5 w-5 shrink-0 items-center justify-center',
  },
  compoundSlots: [
    {
      slots: ['startIcon', 'endIcon'],
      size: 'small',
      class: 'h-4 w-4',
    },
    {
      slots: ['startIcon'],
      loading: true,
      class: 'hidden',
    },
  ],
  variants: {
    variant: {
      contained:
        'bg-gradient-primary text-white transition-none hover:bg-wx-brand-600 hover:bg-none',
      outlined:
        'bg-white ring-1 ring-wx-neutral-300 hover:bg-wx-neutral-50 hover:ring-wx-neutral-400',
      text: 'border-none text-wx-primary hover:bg-wx-neutral-100 hover:text-wx-neutral-900',
    },
    theme: {
      light: '',
      dark: '',
    },
    size: {
      small: 'px-3 py-2 text-base',
      medium: 'px-4 py-2',
      large: 'px-4 py-4',
    },
    color: {
      primary: 'text-wx-primary',
      secondary: 'text-wx-secondary',
      error: 'text-wx-danger-dark',
      success: 'text-wx-success-dark',
      warning: 'text-wx-warning-dark',
      white: 'text-white',
      inherit: 'text-inherit',
    },
    loading: {
      true: 'pointer-events-none',
    },
    disabled: {
      true: 'pointer-events-none',
    },
    fullWidth: {
      true: 'w-full',
    },
    grouped: {
      vertical: '',
    },
  },
  compoundVariants: [
    {
      size: ['medium', 'large'],
      class: 'text-base leading-6',
    },
    {
      variant: 'contained',
      loading: true,
      class: 'bg-wx-brand-500',
    },
    {
      variant: 'outlined',
      loading: true,
      class: 'bg-wx-neutral-100 text-wx-neutral-600',
    },
    {
      variant: ['contained', 'outlined'],
      disabled: true,
      class: 'bg-wx-neutral-200 text-wx-neutral-400',
    },
    {
      variant: ['contained'],
      color: ['primary'],
      class:
        'bg-wx-primary bg-none text-wx-contrast-primary hover:bg-wx-primary hover:brightness-75',
    },
    {
      variant: ['contained'],
      color: ['secondary'],
      class:
        'bg-wx-secondary bg-none text-wx-contrast-secondary hover:bg-wx-secondary hover:brightness-75',
    },
    {
      variant: ['contained'],
      color: ['error'],
      class:
        'bg-wx-danger-dark bg-none text-white hover:bg-wx-danger-dark hover:brightness-75',
    },
    {
      variant: ['contained'],
      color: ['success'],
      class:
        'bg-wx-success-dark bg-none text-white hover:bg-wx-success-dark hover:brightness-75',
    },
    {
      variant: ['contained'],
      color: ['warning'],
      class:
        'bg-wx-warning-dark bg-none text-white hover:bg-wx-warning-dark hover:brightness-75',
    },
    {
      theme: 'dark',
      disabled: true,
      class: 'bg-wx-neutral-900 text-wx-neutral-600',
    },
    {
      variant: ['outlined'],
      grouped: ['vertical'],
      class: 'rounded-none first:rounded-t-lg last:rounded-b-lg focus:z-50',
    },
  ],
  defaultVariants: {
    size: 'medium',
    variant: 'text',
    theme: 'light',
  },
});
