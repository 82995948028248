import { Typography, TypographyProps } from '@mui/material';
import { ElementType } from 'react';
import cx from 'classnames';

interface MuiTypographyProps<C> {
  component?: C;
  uppercase?: boolean;
}

export function MuiTypography<C extends ElementType>(
  props: TypographyProps<C, MuiTypographyProps<C>>,
) {
  return (
    <Typography
      {...props}
      className={cx(props.className, {
        uppercase: props.uppercase,
      })}
    />
  );
}
